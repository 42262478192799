interface UnexpiredSubscribedPlan {
  canMakeNextPayment: false;
  addons?: PlanAddon[];
}

interface ExpiredSubscribedPlan {
  id: string;
  numUsers: number;
  displayName: string;
  currencyCode: string;
  canMakeNextPayment: true;
  renewalDate: string;
  willPaymentAutoRenew: boolean;
  customPricing: boolean;
  status:
    | 'active'
    | 'cancelled'
    | 'expired'
    | 'payment_progress'
    | 'expired_grace';
  price: [
    {
      id: string;
      amount: number;
      unit: 'month' | 'year';
    }
  ];
  function?: {
    type: FunctionTypes;
  };
  addons: PlanAddon[];
}

export interface PlanAddon {
  addonId: string;
  startDate: string;
  endDate: string;
}

export enum PlanAddonTypes {
  Tally = 'Tally-Integration',
  ActivityLogs = 'Activity-Logs',
  ComplexBot = 'Complex-Bot',
}

export enum FunctionTypes {
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
}

export enum PaymentStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  RECEIVED = 'RECEIVED',
}

export type SubscribedPlan = ExpiredSubscribedPlan | UnexpiredSubscribedPlan;

export interface GetSubscribedPlanResponse {
  subscribedPlan: SubscribedPlan;
}

export interface GetPlanStatusResponse {
  emailVerificationRequired: boolean;
  planPaymentStatus?: {
    id: string;
    currencyCode: string;
    name: string;
    price: [
      {
        id: string;
        amount: number;
        unit: 'month' | 'year';
      }
    ];
    paymentStatus: PaymentStatus;
  };
  planSubscribed?: SubscribedPlan;
}
